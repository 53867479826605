import React from "react"
import { FormattedMessage } from "react-intl"

import Layout from "../components/layout"
import FullWidthImage from "../components/full-width-image"
import SEO from "../components/seo"
import ContentWrapper from "../components/content-wrapper"
const ServicesPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <SEO page="services" />
    <FullWidthImage page="services" />
    <ContentWrapper>
      <h1>
        <FormattedMessage id="services" />
      </h1>
      <h2>
        <FormattedMessage id="our_service_for_you_headline" />
      </h2>
      <p>
        <FormattedMessage id="our_service_for_you_text_1" />
      </p>
      <p>
        <strong>
          <FormattedMessage id="our_service_for_you_text_2" />
        </strong>
      </p>
      <p>
        <FormattedMessage id="our_service_for_you_text_3" />{" "}
        <a href="mailto:office@eurobram.de">office@eurobram.de</a>
      </p>
    </ContentWrapper>
  </Layout>
)

export default ServicesPage
